<template>
  <div class="common_sub_container">
    <h2>USER MANAGEMENT</h2>
    <section>
      <div class="action">
        <div class="two_profile_tabs">
          <a
            @click="showTab1=true,showTab2=false"
            v-bind:class="{ active: showTab1 }"
            class="tab_buttons"
          >ADD USER</a>
          <a
            @click="showTab2=true,showTab1=false"
            v-bind:class="{ active: showTab2 }"
            class="tab_buttons"
          >VIEW/ EDIT USERS</a>
        </div>
      </div>
      <UserManagementAddUser v-if="showTab1"  @redirect="showTab2=true,showTab1=false"/>
      <UserManagementEditUser v-if="showTab2" ref="userTable" />
    </section>
  </div>
</template>
<script>
import UserManagementAddUser from "./UserManagement_AddUser.vue";
import UserManagementEditUser from "./UserManagement_EditUser.vue";
export default {
  name: "x",
  components: {
    UserManagementAddUser,
    UserManagementEditUser
  },
  created(){
    this.$emit("breadCrumbData",["User Management"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  },
  methods: {
    searchValue(value) {
      this.$refs.userTable.filterText = value.target.value
    }
  }
};
</script>
<style scoped>
.action .search-text {
	 float: right;
}
 .action .search-text input {
	 width: 250px;
	 padding: 8px;
	 margin-bottom: 10px;
}
 .action .two_tabs {
	 float: left;
	 margin-bottom: 10px;
}
 .action .two_tabs .tab_buttons {
	 background: #004a7c;
	 color: white;
	 margin-right: 5px;
	 padding: 10px;
}
 .action .two_tabs .tab_buttons.active {
	 background: white;
	 border: 1px solid #004a7c;
	 color: #004a7c;
	 margin-right: 5px;
	 padding: 9px;
}
 
</style>



<style lang="scss">

$theme-color: #06A5ED;
.jobs_table {
	border-top: 5px solid $theme-color;

	table {
		width: 100%;
		border-collapse: collapse;
		border-left: 1px solid #ccc;
		font-size: 14px;
		margin-bottom: 15px;

		tr {
			th {
				background: #004a7c;
				padding: 20px 10px;
				color: #ccc;
				font-weight: 700;
				border: 1px solid #222;
				border-left: 0;
				border-top: 0;
				text-align: left;
			}

			td {
				background: #eee;
				padding: 20px 10px;
				color: #333;
				font-weight: 400;
				border: 1px solid #ccc;
				border-left: 0;
				border-top: 0;
				text-align: left;
				vertical-align: top;

				p {
					padding: 0 0 10px 0;

					b {
						font-weight: 700;
					}
				}

				p.text-center {
					text-align: center;
				}

				span.label {
					display: inline-block;
					font-weight: 700;
					background: #000;
					color: #fff;
					border-radius: 4px;
					padding: 5px;
					text-align: center;
					margin-bottom: 5px;

					&.green {
						background: #46aa42;
					}
				}

				.icons_wrapper {
					padding: 10px 0;
					display: flex;
					justify-content: space-between;
					position: relative;

					a {
						display: inline-block;
						width: 24px;
						height: 24px;

						.material-icons {
							font-size: 18px;
							position: relative;
							top: 0;
							margin: 0;
							font-weight: normal;

							&.blue {
								color: rgb(45, 91, 241);
							}

							&.red {
								color: rgb(241, 74, 45);
							}

							&.green {
								color: #46aa42;
							}

							&.orange {
								color: orange;
							}

							&.grey {
								color: grey;
							}

						}
					}
				}

				.bt {
					width: 120px;
					height: 40px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					display: block;
					margin: 5px 0;
				}

				.bt_black {
					background: #063657;
					color: #fff;
				}

				.bt_gold {
					background: $theme-color;
					color: #000;
				}
			}
		}
	}
}

.two_profile_tabs {
	padding: 40px 10px 0 10px;
	display: flex;
	justify-content: space-around;

	.tab_buttons {
		display: block;
		width: 40%;
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: #ececec;
		color: #333;
		border: 1px solid #ddd;

		span.label {
			display: none;
			background: $theme-color;
			color: #000;
			padding: 5px 10px;
			border-radius: 4px;
		}
	}

	.tab_buttons.active {
		background: #004a7c;
		color: #fff;
	}
}

</style>