<template>
	<div class="scoped_cover_div">
		<div class="jobs_table sp">
			<div class>
				<section>
					<div class="form_section1">
						<div class="user_type">
							<label class="chk_label">
								<span>User</span>
								<input name="usertype" type="radio" class="chk_prefered" value="0" id="hub-admin"
									v-model="user_type" />
							</label>
							<label class="chk_label">
								<span>Hub Admin</span>
								<input name="usertype" type="radio" class="chk_prefered" value="1" id="user"
									v-model="user_type" />
							</label>
							<label class="chk_label">
								<span>Customer Service User</span>
								<input name="usertype" type="radio" class="chk_prefered" value="2" id="user"
									v-model="user_type" />
							</label>
							<label class="chk_label">
								<span>Super Admin</span>
								<input name="usertype" type="radio" class="chk_prefered" value="3" id="user"
									v-model="user_type" />
							</label>
						</div>

						<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
							<table>
								<tr>
									<td>
										<ValidationProvider name="full name" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Employee ID
													<span>*</span>
												</label>
												<input type="text" placeholder="ID Character Limit 30"
													v-model="user.employee_id" :maxlength="max2"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="email" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Email
													<span>*</span>
												</label>
												<input type="text" placeholder="Email Character Limit 60"
													v-model="user.email" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="password" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Password
													<span>*</span>
												</label>
												<input type="Password" placeholder="Password Character Limit 60"
													v-model="user.password" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
												<div>
													<small :class="passwordLengthValid ? 'valid' : 'invalid'">At least 8 characters</small> <br>
													<small :class="passwordLowerValid ? 'valid' : 'invalid'">Must Contains lowercase letter</small> <br>
													<small :class="passwordUpperValid ? 'valid' : 'invalid'">Must Contains uppercase letter</small> <br>
													<small :class="passwordNumberValid ? 'valid' : 'invalid'">Must Contains a number</small> <br>
													<small :class="passwordSpecialValid ? 'valid' : 'invalid'">Must Contains special character</small>
												</div>
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="full name" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Employee Name
													<span>*</span>
												</label>
												<input type="text" placeholder="Name Character Limit 30"
													v-model="user.name" :maxlength="max2"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									
								</tr>

								<tr>
									<td>
										<ValidationProvider name="branch" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Branch Name
													<span>*</span>
												</label>
												<select v-model="user.branch_id" @change="fetchBranch"
													:class="errors[0] ? 'warning-border' : ''">
													<option value="">Select Branch</option>
													<option v-for="(branch, i) in branches" :key="'branch' + i"
														:value="branch.id">{{ branch.name }}</option>
												</select>
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<!-- <tr>
									<ValidationProvider name="department" rules="required" v-slot="{ errors }" slim>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Department
													<span>*</span>
												</label>
												<select v-model="user.department_id" @change="fetchCostDepartment"
													:class="errors[0] ? 'warning-border' : ''">
													<option value="">Select Department</option>
													<option v-for="(department, i) in departments"
														:key="'delivery_department_code' + i"
														:value="department.department_name">
														{{ department.department_name }}</option>

												</select>

											</div>
										</td>
									</ValidationProvider>
									<td>
										<ValidationProvider name="cost" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Cost Center
													<span>*</span>
												</label>
												<select v-model="user.cost_center_id"
													:class="errors[0] ? 'warning-border' : ''">
													<option value="" disabled>Select Cost center</option>
													<option v-for="(data, i) in costs" :value="data.id" :key="'data' + i">
														{{ data.name }}</option>
												</select>
												//
												<select v-model="user.cost_center_id" :class="errors[0] ? 'warning-border':''">
													<option value="" disabled>Select Cost center</option>
													<option :value="costs.id" >{{costs.name}}</option>	
												</select> 
												//
											</div>
										</ValidationProvider>
									</td>

								</tr> -->
								<tr>
									<td>
										<ValidationProvider name="office" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Office
													<span>*</span>
												</label>
												<input type="text" placeholder="Office Character Limit 60"
													v-model="user.office" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="entity" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Entity
													<span>*</span>
												</label>
												<input type="text" placeholder="Entity Character Limit 60"
													v-model="user.entity" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="department" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Department
													<span>*</span>
												</label>
												<input type="text" placeholder="Department Character Limit 60"
													v-model="user.department" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="section" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Section
													<span>*</span>
												</label>
												<input type="text" placeholder="Section Character Limit 60"
													v-model="user.section" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="designation" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Designation
													<span>*</span>
												</label>
												<input type="text" placeholder="Designation Character Limit 60"
													v-model="user.designation" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="floor" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Floor Number
													<span>*</span>
												</label>
												<input type="text" placeholder="Floor Number Character Limit 60"
													v-model="user.floor" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="desk" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Area
													<span>*</span>
												</label>
												<input type="text" placeholder="Area Character Limit 60"
													v-model="user.area" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="desk" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Desk Number
													<span>*</span>
												</label>
												<input type="text" placeholder="Desk Number Character Limit 60"
													v-model="user.desk" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="address1" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Address 1
													<span>*</span>
												</label>
												<input type="text" placeholder="Address 1 Character Limit 60" disabled
													v-model="branch.address_1" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="address2" rules="required|max:60" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Address 2
													<span>*</span>
												</label>
												<input type="text" placeholder="Address 2 Character Limit 60" disabled
													v-model="branch.address_2" :maxlength="max"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													City
													<span>*</span>
												</label>
												<input type="text" placeholder="City Character Limit 30" disabled
													v-model="branch.city" :maxlength="max2"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													State
													<span>*</span>
												</label>
												<input type="text" placeholder="State Character Limit 30" disabled
													v-model="branch.state" :maxlength="max2"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Suburb
											</label>
											<input type="text" placeholder="Suburb Character Limit 30" disabled
												v-model="branch.suburb" :maxlength="max2" />
										</div>
									</td>
									<td>
										<ValidationProvider name="postal" rules="required|max:10" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Postal Code
													<span>*</span>
												</label>
												<input type="text" placeholder="Postal Code Character Limit 10" disabled
													v-model="branch.postal_code" :maxlength="max1"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="country" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Country
													<span>*</span>
												</label>
												<input type="text" placeholder="Country Character Limit 30" disabled
													v-model="branch.country" :maxlength="max2"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<ValidationProvider name="phone" rules="required|max:20" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Phone
													<span>*</span>
												</label>
												<input type="text" placeholder="Phone Character Limit 20" disabled
													v-model="branch.phone" :maxlength="max4"
													:class="errors[0] ? 'warning-border' : ''" />
											</div>
										</ValidationProvider>
									</td>
								</tr>
							</table>
						</ValidationObserver>
					</div>
				</section>

				<section class="centered">
					<a class="link_bt bt_cancel">Cancel</a>
					<a class="link_bt bt_save" @click="submit">ADD</a>
				</section>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "UserManagement",
	data() {
		return {
			max: 60,
			max2: 30,
			max1: 10,
			max4: 20,
			user: {
				is_admin: 0,
				is_super_admin: 0,
				is_service_user: 0,
				name: "",
				email: "",
				branch_id: "",
				cost_center_id: "",
				department_id: "",
				office:'',
				entity:'',
				department:'',
				section:'',
				designation:'',
				floor: '',
				area:'',
				desk: '',
				password: '',
			},
			user_type: 0,
			branches: [],
			cost_centers: [],
			departments: [],
			costs: [],
			branch: {
				address_1: "",
				address_2: "",
				city: "",
				state: "",
				suburb: "",
				postal_code: "",
				country: "",
				phone: ""
			}
		};
	},
	created() {
		this.fetchBranches();
		// this.fetchCost();
		this.fetchDepartment();
	},
	methods: {
		fetchBranches() {
			this.axios
				.get("/api/branch/view")
				.then(response => {
					this.branches = response.data.branches;
				})
				.catch(error => {
					this.toast.error();
				});
		},
		// fetchDepartment(){
		//   if(this.user.branch_id != ''){
		//     this.axios.get("/api/location/showdepartment/"+this.user.branch_id)
		//       .then(response => {
		//         this.departments = response.data.department;
		//       })
		//       .catch(error => {
		//         this.toast.error();
		//       });
		//   }
		// },

		fetchDepartment() {

			this.axios.get("/api/department/view")
				.then(response => {
					this.departments = response.data.department;
				})
				.catch(error => {
					this.toast.error();
				});

		},

		fetchCostDepartment() {

			if (this.user.department_id != '') {
				this.axios.get("/api/cost/view/" + this.user.department_id)
					.then(response => {
						this.costs = response.data.cost_centers;

						if (this.costs.length > 0) {

							this.user.cost_center_id = this.costs[0].id;
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		fetchBranch() {
			console.log(this.user.branch_id);
			if (this.user.branch_id != '') {
				this.axios
					.get("/api/branch/view/branchId/" + this.user.branch_id)
					.then(response => {
						this.branch.address_1 = response.data.branches.address_1;
						this.branch.address_2 = response.data.branches.address_2;
						this.branch.city = response.data.branches.city;
						this.branch.state = response.data.branches.state;
						this.branch.suburb = response.data.branches.suburb;
						this.branch.postal_code = response.data.branches.postal_code;
						this.branch.country = response.data.branches.country;
						this.branch.phone = response.data.branches.phone;
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		// fetchCost(){
		//   this.axios.get("/api/cost")
		//   .then(response => {
		//     this.cost_centers = response.data.cost_centers;
		//   })
		//   .catch(error => {
		//     this.toast.error();
		//   });
		// },
		setUserType() {
			if (this.user_type == 0) {
				this.user.is_admin = 0;
				this.user.is_super_admin = 0;
				this.user.is_service_user = 0;
			} else if (this.user_type == 1) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 0;
				this.user.is_service_user = 0;
			} else if (this.user_type == 2) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 1;
				this.user.is_service_user = 1;
			} else if (this.user_type == 3) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 1;
				this.user.is_service_user = 0;
			}
		},
		submit() {
			this.setUserType();
			this.$refs.observer.validate();
			this.axios
				.post("/api/user/", this.user)
				.then(response => {
					this.toast.success(response.data.msg);
					this.$emit("redirect");
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	watch: {
		// 'user.branch_id'(){
		//   this.fetchDepartment();
		// }
	},
	computed: {
		passwordLengthValid() {
			return this.user.password.length >= 8 && this.user.password.length <= 12;
		},
		passwordLowerValid() {
			return /[a-z]/.test(this.user.password);
		},
		passwordUpperValid() {
			return /[A-Z]/.test(this.user.password);
		},
		passwordNumberValid() {
			return /[0-9]/.test(this.user.password);
		},
		passwordSpecialValid() {
			return /[@$!%*?&#]/.test(this.user.password);
		}
  }
};
</script>
<style  scoped >
.scoped_cover_div {
	margin: 0;
}

.scoped_cover_div section {
	padding: 0;
}

.scoped_cover_div .form_section1 {
	margin-top: 0;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
	border: 0;
	padding: 0;
}

.user_type {
	padding: 10px;
}

.chk_label {
	display: inline-block;
	margin-right: 20px;
	background: #fff;
	height: 40px;
	border: 1px solid #ccc;
}

.chk_label span {
	display: inline-block;
	position: relative;
	padding: 0 0 0 20px;
	top: 5px;
}

.chk_label input {
	display: inline-block;
	position: relative;
	top: 10px;
}

.valid {
    color: green;
}
.invalid {
    color: red;
}
.warning-border {
    border-color: red;
}
</style>
